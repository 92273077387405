<template>
  <section class="m-2">
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="pay_box_sale-id"
              v-model="filters.saleId"
              :label="$t('Id da venda')"
            />
          </b-col>
          <b-col>
            <e-person-search
              id="pay_box_sale-client"
              v-model="filters.customer"
              :label="$t('Cliente')"
              is-customer
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="pay_box_sale-origin"
              v-model="filters.origin"
              :label="$t('Origem')"
              :placeholder="$t('Selecione')"
              type="vue-select"
              :options="saleOriginOptions()"
            />
          </b-col>
          <b-col
            v-if="isPendingSaleLayout"
            md="2"
          >
            <FormulateInput
              id="pay_box_sale-delivery_status"
              v-model="filters.deliveryStatus"
              :label="$t('Status da entrega')"
              :placeholder="$t('Selecione')"
              type="vue-select"
              :options="deliveryStatusOptions()"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="pay_box_sale-is_consigned"
              v-model="filters.isConsigned"
              type="vue-select"
              :label="$t('Consignado')"
              :placeholder="$t('Todos')"
              :options="yesNoTypes()"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <FormulateInput
              id="pay_box_sale-period"
              v-model="filters.period"
              type="date-range-picker"
              :label="$t('Período')"
              opens="right"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="payBoxSale"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="tableEmptyMessage"
        :fields="fields"
        :items="isPendingSaleLayout ? pendingSales : sales"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(issueDate)="row">
          {{ row.item.issueDate | datetime }}
        </template>

        <template #cell(status)="row">
          <e-status-badge
            :status="row.item.status"
            :options="saleStatusOptions()"
          />
        </template>

        <template #cell(deliveryStatus)="row">
          <e-status-badge
            v-if="row.item.deliveryStatus"
            :status="row.item.deliveryStatus"
            :options="deliveryStatusOptions()"
          />
          <span v-else> - </span>
        </template>

        <template #cell(delivery)="row">
          <e-status-badge
            :status="row.item.delivery"
            type="yesNo"
          />
        </template>

        <template #cell(consigned)="row">
          <e-status-badge
            :status="row.item.consigned"
            type="yesNo"
            :true-class-style="row.item.pendingPaymentValue > 0 ? 'warning' : ''"
            :title="
              row.item.consigned && row.item.pendingPaymentValue > 0 ? 'Pendente de pagamento' : ''
            "
          />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :show-update="false"
            :show-delete="false"
            :buttons="[
              {
                icon: 'eye-fill',
                variant: 'primary',
                title: $t('Visualizar venda'),
                event: 'show-sale-detail',
              },
            ]"
            @show-sale-detail="onShowSaleInfoSidebar(row.item)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <sale-info-sidebar
      ref="saleInfoSidebar"
      :is-pending-layout="isPendingSaleLayout"
      @get-sale-data="getData"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import { loadingOverlay, saleDomain, statusTypes } from '@/mixins'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import EPageSizeSelector from '@/views/components/pagination/EPageSizeSelector.vue'
import EGridActions from '@/views/components/EGridActions.vue'
import EPaginationSummary from '@/views/components/pagination/EPaginationSummary.vue'
import EFilters from '@/views/components/EFilters.vue'
import EStatusBadge from '@/views/components/EStatusBadge.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import saleInfoSidebar from './SaleInfoSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    BTable,
    BCardActions,
    EPageSizeSelector,
    EGridActions,
    EPersonSearch,
    EPaginationSummary,
    saleInfoSidebar,
    EFilters,
    EStatusBadge,
  },

  mixins: [loadingOverlay, statusTypes, saleDomain],

  data() {
    return {
      fetching: false,
    }
  },

  computed: {
    ...mapState('pages/pdv/manageSales', {
      sales: 'sales',
      pendingSales: 'pendingSales',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),

    isPendingSaleLayout() {
      return this.$route.name === 'pdv-pay-box-delivery-sales'
    },

    tableEmptyMessage() {
      if (this.isPendingSaleLayout) {
        return this.getEmptyTableMessage(this.$tc('PAY_BOX_SALE.PENDING_SALE.NAME'), 'female')
      }

      return this.getEmptyTableMessage(this.$t('Venda'), 'female')
    },

    actionButtons() {
      const manageSale = [
        {
          icon: 'printer-fill',
          variant: 'primary',
          title: this.$t('Reimprimir Nota Fiscal'),
          event: 'print-sat',
        },
        {
          icon: 'x-circle',
          variant: 'danger',
          title: this.$t('Cancelar venda'),
          event: 'cancel-sale',
        },
      ]
      const finishSale = [
        {
          icon: 'check-circle',
          variant: 'primary',
          title: this.$t('Finalizar no caixa'),
          event: 'send-to-pay-box',
        },
        {
          icon: 'x-circle',
          variant: 'danger',
          title: this.$t('Cancelar venda'),
          event: 'cancel-sale',
        },
      ]

      if (this.isPendingSaleLayout) return finishSale

      return manageSale
    },

    fields() {
      let pendingFields = []
      let manageFields = []
      if (this.isPendingSaleLayout) {
        pendingFields = [
          {
            label: this.$t('Status da entrega'),
            key: 'deliveryStatus',
            thClass: 'text-center',
            tdClass: 'text-center',
            thStyle: { width: '120px' },
            sortable: true,
          },
        ]
      } else {
        manageFields = [
          {
            label: this.$t('Fiscal'),
            key: 'invoice.invoiceNumber',
            thClass: 'text-center',
            tdClass: 'text-left',
            formatter: (val, index, item) => (item.invoice ? `${item.invoice.model}: ${val}` : '-'),
            thStyle: { width: '120px' },
            sortable: true,
          },
        ]
      }

      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Data'),
          key: 'issueDate',
          class: 'text-center',
          thStyle: { width: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Venda'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Origem'),
          key: 'origin',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: val => this.saleOriginLabel[val],
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Cliente'),
          key: 'customer.name',
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        ...manageFields,
        {
          label: this.$t('Total'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: val => this.$options.filters.currency(val),
          sortable: true,
        },
        {
          label: this.$t('Data Entrega'),
          key: 'deliveryDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: val => this.$options.filters.date(val, '-'),
        },
        {
          label: this.$t('Consignado'),
          key: 'consigned',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Delivery'),
          key: 'delivery',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        ...pendingFields,
      ]
    },
  },

  mounted() {
    this.setCurrentPage(1)
    this.getData()
  },

  methods: {
    ...mapActions('pages/pdv/manageSales', [
      'fetchSales',
      'fetchPendingSales',
      'setCurrentPage',
      'setPageSize',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          if (this.isPendingSaleLayout) await this.fetchPendingSales()
          else await this.fetchSales()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onShowSaleInfoSidebar(item) {
      this.$refs.saleInfoSidebar.show(item.id)
    },
  },
}
</script>

<style lang="scss" scoped></style>
